import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: "What is Adnova?",
    answer: "Adnova is a comprehensive platform that unifies your digital marketing efforts by connecting with top advertising platforms like Google, Facebook, and LinkedIn. We help you manage, create, and optimize your campaigns in one place, saving you time and effort."
  },
  {
    question: "How does Adnova integrate with advertising platforms?",
    answer: "Adnova seamlessly connects with Google Ads, Facebook Ads, LinkedIn Ads, and more, allowing you to manage multiple campaigns across platforms from a single dashboard. Our platform uses advanced API connections to synchronize data and streamline the advertising process."
  },
  {
    question: "What advertising platforms does Adnova support?",
    answer: "Currently, Adnova supports Google Ads, Facebook Ads, and LinkedIn Ads. We are working on integrating additional platforms to further expand your advertising reach."
  },
  {
    question: "How does Adnova help simplify campaign creation?",
    answer: "Adnova’s user-friendly interface offers an intuitive campaign creation wizard, which guides you through the entire setup process. Plus, our AI-powered prompt assists in recommending the best campaign types and formats based on your advertising goals."
  },
  {
    question: "What’s the difference between Adnova’s subscription plans?",
    answer: "Adnova offers various plans: Free, Bronze, Silver, and Gold. Each plan has unique features, from essential ad management tools in the Free plan to advanced analytics, premium integrations, and priority support in our Gold plan."
  },
  {
    question: "Can Adnova recommend the best ad types for my goals?",
    answer: "Yes! Adnova’s AI-powered tool analyzes your advertising goals and suggests the most effective campaign types, ad formats, and platforms for achieving those goals."
  },
  {
    question: "What types of ads can I create on Adnova?",
    answer: "Adnova supports multiple ad types across platforms, including Video Ads, Text Ads, Image Ads, and Carousel Ads, among others. Our platform helps you choose the right ad type based on your goals and audience."
  },
  {
    question: "Is Adnova suitable for small businesses?",
    answer: "Absolutely! Adnova’s flexible pricing and easy-to-use platform are designed to support businesses of all sizes, from startups to large enterprises. Our goal is to make powerful advertising tools accessible to everyone."
  },
  {
    question: "Can Adnova help with optimizing my ad campaigns?",
    answer: "Yes. Adnova provides data-driven insights and recommendations, helping you optimize your campaigns and maximize return on investment (ROI). You can view performance metrics across platforms and make adjustments for better results."
  },
  {
    question: "How do I get started with Adnova?",
    answer: "Getting started with Adnova is simple. Sign up, choose a subscription plan that suits your needs, and begin connecting your advertising accounts. Our intuitive interface and setup wizard will guide you through the initial steps."
  },
  {
    question: "Does Adnova offer customer support?",
    answer: "Yes. We provide dedicated support to all our users, with priority support available in our Silver and Gold plans. Our support team is here to help you with any questions or technical issues."
  },
  {
    question: "What makes Adnova different from other marketing platforms?",
    answer: "Adnova’s unique value lies in its all-in-one approach, AI-driven insights, and seamless integrations with major ad platforms. We make campaign creation and optimization simpler, faster, and more effective, giving you more time to focus on growing your business."
  },
  {
    question: "How does Adnova handle data privacy and security?",
    answer: "Adnova is committed to protecting your data. We use robust encryption and comply with industry standards to ensure the privacy and security of your information across all integrated platforms."
  },
  {
    question: "How can I upgrade or change my Adnova subscription?",
    answer: "You can easily upgrade or change your subscription plan through your account settings. Adnova allows you to switch plans to suit your evolving business needs."
  },
  {
    question: "Does Adnova offer any customization options for my campaigns?",
    answer: "Yes, Adnova offers various customization options, allowing you to tailor ad formats, budgets, target audiences, and more to suit your specific campaign objectives. You can also create personalized ads for different demographics and regions."
  }
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqData.map(({ question, answer }, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
